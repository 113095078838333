export default () => {
  if (document.getElementById('app')) {
    return import(/* webpackChunkName: "app" */ '../app/app')
      .then(({ default: App }) => {
        const app = new App();
        return app.init();
      })
      // eslint-disable-next-line no-console
      .catch((error) => console.log(`App : ${error}`));
  }
  return null;
};
